<template>
 
  <section class="Categories">
    <div v-for="pro in product" :key="pro.id" class="Category">
      <div>
        <article>
          <div>
            <div class="img-container">
              <a>
                <img 
                :src="getOriginalImageLink(pro.mainImagePath)"
                    @error="getImageLink"
                alt="" class="cat-img"/>
              </a>
            </div>
            <div class="caption">
              <h2>
                {{ pro.code }}
              </h2>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { watch } from "vue";
export default {
  name: "ProductsComonent",

  setup() {
    const route = useRoute();
    const product = ref([]);
    let pageNumber = ref(1);

    const categoryId = ref(route.params.id);
    const getImageLink = function(event) {
      var href = "https://drive.google.com/thumbnail?id=";
      var id = event.target.src.split('id=')[1] ? event.target.src.split('id=')[1] : event.target.src.split('/d/')[1];
      id = id.split('/')[0];
      event.target.src = href + id;
    }
    const getOriginalImageLink = function(image) {
      var href = "https://lh3.google.com/u/0/d/";
      var id = image.split('id=')[1] ? image.split('id=')[1] : image.split('/d/')[1];
      id = id.split('/')[0];
      return href + id;
    }
    const fetchProducts = async () => {
      const Url =
        "https://panel.a2ztr.com/api/productapi/getproductsbysubcategorypagination?subCategoryId=" +
        categoryId.value +
        "&pageNumber=" +
        pageNumber.value +
        "&_pageSize=15&pageSize=15";
      const response = await fetch(Url);
      const data = await response.json();
      product.value.push(...data.data);
      pageNumber.value++;
    };
    const nextPage = async () => {
      fetchProducts();
    };
    watch(
      () => route.params.id,
      async (newId, oldId) => {
        if (newId !== oldId) {
          categoryId.value = newId;
          pageNumber.value = 1;
          product.value = [];
          fetchProducts();
        }
      }
    );
    onMounted(async () => {
      await fetchProducts();
    });

    return { product, nextPage , getImageLink,getOriginalImageLink};
  },
};
</script>

<style scoped>
.page {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 10vh;
}

#products {
  /* display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 50px; */
  /* display: flex;
  flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 50px; */
}

.product {
  /* margin-bottom: 30px;
  border-bottom: 1px solid #f5f4ef;
  border-radius: 8px;
  min-width: 30%;
  display:flex;
  flex-direction :column;
  justify-content:center;
  align-items: center; */
  margin-bottom: 5px;
  border: 1px solid #f5f4ef;
  border-radius: 8px;
}
.Categories {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(3, 1fr);
  padding: 100px 15%;
  box-sizing: border-box;
  margin : 0 30px 0 30px;
}

.Category {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  margin-bottom: 5px;
  border: 1px solid #f5f4ef;
  border-radius: 8px;
  /* flex-shrink: 1;
  flex-wrap: nowrap; */
}
.product img{
max-width:400px;
width:100%;
border-radius :8px;
}
.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px;
}

.pagination .page-item {
  box-shadow: 0 2px 30px rgba(10, 9, 9, 0.352);
  border-radius: 50%;
  border: 1px solid #f5f4ef;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 40px;
  transition: all 0.3s ease;
  background-color: #f5f4ef;
}

.pagination .page-item:hover {
  transform: scale(1.1);
  background-color: #0e0e0e;
}
.cat-img{
  border-radius: 8px;
  width:100%;
}
h2 a {
  font-size: 20px;
  font-weight: 600;
  padding: 5px;
}
@media (max-width: 1000px) {
  .Categories {
    grid-template-columns: repeat(1, 1fr);
    padding: 100px 15%;
  }
}
</style>
