<template>
    <div class="container">
        <div v-for="(cat, i) in subcategories" :key="i" class="product">
            <router-link :to="{ name: 'Products', params: { id: cat.id } }">
                <img 
                :src="getOriginalImageLink(cat.imagePath)"
                    @error="getImageLink"
                    alt="" class="cat-img" />
                <!-- <strong>{{ cat.name }}</strong> -->
            </router-link>
            <div class="caption">
              <strong>
                  {{  cat.name  }}
              </strong>
          </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
export default {
    name: "SubCategoryComponent",
    setup() {
        const subcategories = ref([]);
        const route = useRoute();
        const categoryId = ref(route.params.id);
        const getImageLink = function(event) {
      var href = "https://drive.google.com/thumbnail?id=";
      var id = event.target.src.split('id=')[1] ? event.target.src.split('id=')[1] : event.target.src.split('/d/')[1];
      id = id.split('/')[0];
      event.target.src = href + id;
    }
    const getOriginalImageLink = function(image) {
      var href = "https://lh3.google.com/u/0/d/";
      var id = image.split('id=')[1] ? image.split('id=')[1] : image.split('/d/')[1];
      id = id.split('/')[0];
      return href + id;
    }
        const displaySubCats = async () => {
            const Url =
                "https://panel.a2ztr.com/api/types/getsubcategoriesbycategoryid?categoryId=" +
                categoryId.value;
            const result = await fetch(Url);
            const data = await result.json();
            subcategories.value = data;
        };
        onMounted(() => {
            displaySubCats();
        });
        return { subcategories , getImageLink,getOriginalImageLink};
    },
};
</script>

<style scoped>
.container {
    padding: 100px 15%;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.product {
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    border: 1px solid #f5f4ef;
    border-radius: 8px;
    padding: 10px;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
       width: 30%;
    max-width: 300px;
     */
}
.cat-img{
  border-radius: 8px;
  width:100%;
}
/* @media (max-width: 1000px) {
    .product {
        width: 45%;
    }
} */
@media (max-width: 1000px) {
  .container {
    grid-template-columns: repeat(1, 1fr);
    padding: 100px 15%;
  }
}
</style>
