<template>
    <div class="maincat">
        <div v-for="cat in Categories" :key="cat.id" class="cat">
            <router-link :to="{ name: 'SubCategoryComponent', params: { id: cat.id } }">
                <figure>
                    <img :src="getOriginalImageLink(cat.imagePath)"
                    @error="getImageLink"  alt="" />
                    <figcaption>
                        <strong> {{ cat.name }} </strong>
                    </figcaption>
                </figure>
            </router-link>
        </div>
    </div>
</template>

<script>
import { onMounted } from "vue";
import { computed } from "vue";
import { useStore } from "vuex";
export default {
    name: "MainCategoriesComponent",
    setup() {
        const store = useStore();
        const Categories = computed(() => store.state.categories);
        const getImageLink = function(event) {
      var href = "https://drive.google.com/thumbnail?id=";
      var id = event.target.src.split('id=')[1] ? event.target.src.split('id=')[1] : event.target.src.split('/d/')[1];
      id = id.split('/')[0];
      event.target.src = href + id;
    }
    const getOriginalImageLink = function(image) {
      var href = "https://lh3.google.com/u/0/d/";
      var id = image.split('id=')[1] ? image.split('id=')[1] : image.split('/d/')[1];
      id = id.split('/')[0];
      return href + id;
    }
        onMounted(async () => {
            await store.dispatch("fetchCategories");
        });

        return { Categories , getImageLink, getOriginalImageLink};
    },
};
</script>

<style scoped>
.maincat {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 50px 20px;
}

.cat {
    width: 30%;
}

.cat a {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    text-align: center;
}

.cat a img {
    width: 100px;
    height:100px;
    border-radius: 50%;
}
</style>
