<template>
  <section class="Categories">
    <div v-for="subcat in subcats" :key="subcat.id" class="Category">
      <div>
        <article>
          <div>
            <div class="img-container">
              <router-link :to="{ name: 'Products', params: { id: subcat.id } }">
                <img 
                :src="getOriginalImageLink(subcat.imagePath)"
                    @error="getImageLink"
                 alt="" class="cat-img"/>
              </router-link>
            </div>
            <div class="caption">
              <h2>
                <router-link :to="{ name: 'Products', params: { id: subcat.id } }">
                  {{ subcat.name }}
                </router-link>
              </h2>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted } from "vue";
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "CategoriesComponent",
  setup() {
    const store = useStore();
    const subcats = computed(() => store.state.subcats);
    const getImageLink = function(event) {
      var href = "https://drive.google.com/thumbnail?id=";
      var id = event.target.src.split('id=')[1] ? event.target.src.split('id=')[1] : event.target.src.split('/d/')[1];
      id = id.split('/')[0];
      event.target.src = href + id;
    }
    const getOriginalImageLink = function(image) {
      var href = "https://lh3.google.com/u/0/d/";
      var id = image.split('id=')[1] ? image.split('id=')[1] : image.split('/d/')[1];
      id = id.split('/')[0];
      return href + id;
    }
    onMounted(async () => {
      await store.dispatch("fetchSubCategories");
    });

    return { subcats , getImageLink,getOriginalImageLink};
  },
};
</script>
<style scoped>
.Categories {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 5px;
  box-sizing: border-box;
  margin : 0 30px 0 30px;
}

.Category {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  margin-bottom: 5px;
  border: 1px solid #f5f4ef;
  border-radius: 8px;
  /* flex-shrink: 1;
  flex-wrap: nowrap; */
}
.img-container{
}
.cat-img{
  border-radius: 8px;
  width:100%;
}
.Category .caption {
  padding: 20px;
}

.caption a {
  color: #3a3d45;
  font-size: 20px;
  font-weight: 600;
  text-overflow: ellipsis;
}

@media (max-width: 1000px) {
  .Categories {
    grid-template-columns: repeat(1, 1fr);
    padding: 100px 15%;
  }
}
</style>
